<template>
  <section>
    <div>
      <b-card class="card-app-design assigned-staff-card">
        <h4>Student Registration Link</h4>
        <!-- <a :href="data.student_registration_link" target="_blank"> -->
        <b-button
          size="sm"
          variant="outline-primary"
          class="btn-tour-skip mr-1"
          @click="openLink(data.student_registration_link)"
        >
          <span class="mr-25 align-middle">Click Here</span>
          <!-- <feather-icon icon="XIcon" size="12" /> -->
        </b-button>
        <!-- </a> -->
      </b-card>
      <staff-home />
    </div>

    <div class="row">
      <!-- <div class="col-lg-8">
        <ProfileStatus :data="data" />
      </div>
      <div class="col-lg-4">
        <AssignedStaff :assigned_staff="data.assignedStaff" />
      </div> -->
      <div class="col-lg-12"></div>

      <div class="col-lg-12">
        <OtherDetails :data="data" />
      </div>
    </div>
    <b-row>
      <!-- <b-col cols="12">
        <applications-documents-pending
          :tableData="applicationDocumentPedingStudents"
        />
      </b-col> -->
    </b-row>
  </section>
</template>

<script>
import { BRow, BCol, BCard, BMediaAside, BMedia, BButton } from "bootstrap-vue";
import { FILESURL } from "@/config";
import { BImg } from "bootstrap-vue";
import AgentServices from "@/apiServices/AgentServices";

import DonutChart from "@/views/components/StaffHomeComponents/DonutChart.vue";
import ApplicationsDocumentsPending from "@/views/components/StaffHomeComponents/ApplicationsWiseStudents.vue";

import AssignedStaff from "./helper/AssignedStaff.vue";
import Applications from "./helper/Applications.vue";
import ProfileStatus from "./helper/ProfileStatus.vue";
import OtherDetails from "./helper/OtherDetails.vue";
import StaffHome from "../StaffHome/StaffHome.vue";

export default {
  components: {
    BImg,
    AssignedStaff,
    Applications,
    ProfileStatus,
    OtherDetails,
    DonutChart,
    ApplicationsDocumentsPending,
    BRow,
    BCol,
    BCard,
    BMediaAside,
    BMedia,
    StaffHome,
    BButton,
  },
  data() {
    return {
      FILESURL,
      data: {},
      appliedOfferLetter: [0, 0],
      feePaidOfferLetter: [0, 0],
      applicationDocumentPedingStudents: [],
    };
  },
  methods: {
    async getHomeData() {
      try {
        const res = await AgentServices.getHomeData();
        if (res.data.status) {
          this.data = res.data.data;

          const applicationStatusCount = res.data.data.applicationStatusCount;

          const appliedToUniversity = applicationStatusCount.find((i) => {
            if (i.status_key === "applied_to_university") {
              return true;
            }
            return false;
          })?.count;

          const offerLetterRecieved = applicationStatusCount.find((i) => {
            if (i.status_key === "conditional_offer_letter_received") {
              return true;
            }
            return false;
          })?.count;

          if (appliedToUniversity && offerLetterRecieved) {
            const add = appliedToUniversity + offerLetterRecieved;
            const atup = Math.round((appliedToUniversity * 100) / add);
            const olrp = Math.round((offerLetterRecieved * 100) / add);

            this.appliedOfferLetter = [atup, olrp];
          }

          const depositPaid = applicationStatusCount.find((i) => {
            if (i.status_key === "advance_fee_paid") {
              return true;
            }
            return false;
          })?.count;

          const offerRecieved = applicationStatusCount.find((i) => {
            if (i.status_key === "unconditional_offer_letter_received") {
              return true;
            }
            return false;
          })?.count;

          if (depositPaid && offerRecieved) {
            const dpor = depositPaid + offerRecieved;
            const dp = Math.round((depositPaid * 100) / dpor);
            const or = Math.round((offerRecieved * 100) / dpor);

            this.feePaidOfferLetter = [dp, or];
          }

          this.applicationDocumentPedingStudents =
            res.data.data.applicationDocumentPedingStudents;
        }
      } catch (err) {
        console.log("Error in getting getHomeData Agent ", err);
      }
    },
    openLink(link) {
      window.open(link, "_blank");
    },
  },
  beforeMount() {
    this.getHomeData();
  },
};
</script>

<style>
.dashboard__heading {
  color: #002884;
  font-weight: bold;
  margin-bottom: 1.2rem;
}
.student-dashboard .card-title {
  color: #000000;
  font-weight: 600;
}
.primary-color {
  color: #F6DC80;
}
</style>
