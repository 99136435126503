<template>
  <section>
    <b-row>
      <b-col v-if="user_type === 'counselor'">
        <b-card class="card-app-design assigned-staff-card">
          <h4>Student Registration Link</h4>
          <!-- <a :href="data.student_registration_link" target="_blank"> -->
          <b-button class="btn btn-sm btn-outline-primary mr-1" @click="openStudentLink()">
            <span class="mr-25 align-middle">Click Here</span>
            <!-- <feather-icon icon="XIcon" size="12" /> -->
          </b-button>
          <!-- </a> -->
        </b-card>
      </b-col>

      <!-- <b-col cols="12" v-if="user_type === 'counselor'">
        <b-card>
          <div class="d-flex">
            <div class="w-25 mr-2">
              <v-select
                placeholder="Lead Type"
                v-model="leadType"
                :options="leadTypeOptions"
                :clearable="true"
                item-text="label"
                :reduce="(item) => item.value"
                @input="
                  () => {
                    handleLeadSourceOptions();
                    getHomeData();
                  }
                "
              />
            </div>
            <div class="w-25">
              <v-select
                placeholder="Lead Source"
                v-model="leadSource"
                :disabled="leadType ? false : true"
                :options="leadSourceOptions"
                :clearable="true"
                item-text="label"
                :reduce="(item) => item.value"
                @input="getHomeData"
              />
            </div>
          </div>
        </b-card>
      </b-col> -->

      <!-- Franchise responses -->
      <b-col cols="12" v-if="user_type === 'counselor' && quiz_responses">
        <quiz-responses :quiz_responses="quiz_responses" />
      </b-col>
      <b-col cols="12">
        <pyramid-chart :data="pyramidChartData"></pyramid-chart>
      </b-col>
      <b-col cols="12">
        <counts :data="studentCounts" header="Students" />
      </b-col>

      <b-col cols="12">
        <e-chart :series="echartData.series" :xAxisData="echartData.xAxisData" @echartDateRange="getEchartDateRange" />
      </b-col>
    </b-row>
    <b-card>
      <b-row>
        <b-col cols="5">
          <donut-chart :series="applicationsStatus" :labels="applicationsStatusLabels" />
        </b-col>
        <b-col cols="7">
          <applications-statuses @statusSelected="applicationStatusSelected"
            @applicationStatusDate="getApplicationStatusDate" :status_id="selectedStatusId"
            :data="applicationStatusCount" :status="selectedStatus" />
        </b-col>
        <b-col cols="12">
          <applications-wise-students :selectedAgentUserId="selectedAgentUserId" :lead="getLeadData"
            :status_id="selectedStatusId" :status="selectedStatus" :applicationStatusDate="applicationStatusDate" />
        </b-col>
      </b-row>
    </b-card>
  </section>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BMediaAside,
  BMedia,
  BAvatar,
  BFormSelect,
  BButton,
} from "bootstrap-vue";
import ApplicationsStatuses from "@/views/components/StaffHomeComponents/ApplicationsStatuses.vue";
import DonutChart from "@/views/components/StaffHomeComponents/DonutChart.vue";
import EChart from "@/views/components/StaffHomeComponents/EChart.vue";
import ApplicationsWiseStudents from "@/views/components/StaffHomeComponents/ApplicationsWiseStudents.vue";
import CommonServices from "@/apiServices/CommonServices";
import Counts from "@/views/components/StaffHomeComponents/Counts.vue";
import PyramidChart from "./PyramidChart.vue";
import CounselorServices from "@/apiServices/CounselorServices";
import store from "@/store";
import { VueSelect } from "vue-select";
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";
import { FILESURL } from "@/config";
import vSelect from "vue-select";
import QuizResponses from "./QuizResponses.vue";

export default {
  components: {
    BAvatar,
    AppCollapse,
    AppCollapseItem,
    BRow,
    BCol,
    BCard,
    ApplicationsStatuses,
    DonutChart,
    EChart,
    ApplicationsWiseStudents,
    BMedia,
    BMediaAside,
    Counts,
    PyramidChart,
    VueSelect,
    BFormSelect,
    vSelect,
    BButton,
    QuizResponses,
  },
  data() {
    return {
      leadType: null,
      leadSource: null,
      leadTypeOptions: [
        { label: "Direct", value: "direct" },
        { label: "Associate", value: "agent" },
        { label: "Franchise", value: "franchise" },
      ],
      leadSourceOptions: [],

      quiz_responses: {},

      FILESURL,
      applicationStatusCount: [],
      applicationsStatus: [],
      applicationsStatusLabels: [],
      applicationWiseStudents: [],
      selectedStatusId: null,
      selectedStatus: {},
      studentCounts: [],
      applicationStatusDate: {
        intake_month: null,
        intake_year: null,
      },
      echartDateRange: {
        startDate: "",
        endDate: "",
      },
      echartData: {
        xAxisData: [],
        series: [],
      },

      assignedAgents: [],
      selectedAgentUserId: "",
      pyramidChartData: [],
      baseURL: window.location.origin,
    };
  },

  computed: {
    user_type() {
      const val = store.getters["user/getUserDetails"].user_type;
      if (val === "counselor") {
        this.getAssignedAgents();
      }
      return val;
    },
  },

  methods: {
    onChange() {
      this.$nextTick(() => {
        this.getHomeData();
      });
    },
    openStudentLink() {
      const link = `${this.baseURL}/register?refer_user_id=${store.getters["user/getUserDetails"].user_id}`;
      window.open(link, "_blank");
    },
    resetValues() {
      this.applicationStatusCount = [];
      this.pplicationsStatus = [];
      this.applicationsStatusLabels = [];
      this.applicationWiseStudents = [];
      this.selectedStatusId = null;
      this.selectedStatus = {};
      this.studentCounts = [];
      this.applicationStatusDate = {
        intake_month: null,
        intake_year: null,
      };
      this.echartDateRange = {
        startDate: "",
        endDate: "",
      };
      this.echartData = {
        xAxisData: [],
        series: [],
      };

      this.assignedAgents = [];
      this.selectedAgentUserId = "";
      this.pyramidChartData = [];
    },

    async handleLeadSourceOptions() {
      this.leadSource = null;
      if (!this.leadType) {
        this.leadSource = null;
      }
      if (this.leadType === "direct") {
        this.leadSourceOptions = [
          {
            label: "Facebook",
            value: "facebook",
          },
          {
            label: "Instagram",
            value: "instagram",
          },
          {
            label: "Away Education Website",
            value: "Away EducationWebsite",
          },
          {
            label: "Alumni",
            value: "alumni",
          },
        ];
      }
      if (this.leadType === "agent") {
        const res = await CounselorServices.getAssignedAgents({
          leadUserType: this.leadType,
        });
        let assignedAgents = res.data.data;
        this.leadSourceOptions = [];
        for (let assignedAgent of assignedAgents) {
          this.leadSourceOptions.push({
            label:
              assignedAgent.user_firstname + " " + assignedAgent.user_lastname,
            value: assignedAgent.user_id,
          });
        }
      }
      if (this.leadType === "franchise") {
        const res = await CounselorServices.getAssignedAgents({
          leadUserType: this.leadType,
        });
        let assignedAgents = res.data.data;
        this.leadSourceOptions = [];
        for (let assignedAgent of assignedAgents) {
          this.leadSourceOptions.push({
            label:
              assignedAgent.user_firstname + " " + assignedAgent.user_lastname,
            value: assignedAgent.user_id,
          });
        }
      }
    },

    async getAssignedAgents() {
      const res = await CounselorServices.getAssignedAgents();

      this.assignedAgents = res.data.data;
    },

    applicationStatusSelected(item) {
      this.selectedStatusId = item.status_id;
      this.selectedStatus = item;
    },

    getApplicationStatusDate(date) {
      this.applicationStatusDate = date;
      this.getHomeData();
    },

    getEchartDateRange({ startDate, endDate }) {
      this.echartDateRange.startDate = startDate;
      this.echartDateRange.endDate = endDate;
      this.getHomeData();
    },

    getLeadData() {
      return {
        leadType: this.leadType,
        leadSource: this.leadType == "direct" ? this.leadSource : null,
        leadSourceUserId: this.leadType !== "direct" ? this.leadSource : null,
      };
    },

    async getHomeData() {
      try {
        const res = await CommonServices.getHomeData({
          application: {
            ...this.applicationStatusDate,
          },
          // agent_user_id: this.selectedAgentUserId,
          echart: {
            ...this.echartDateRange,
          },
          lead: this.getLeadData(),
        });
        if (res.data.status) {
          this.studentCounts = res.data.data.studentCounts;

          this.echartData.xAxisData =
            res.data.data.formattedMonthlyStudentsJoined.xAxisData;
          this.echartData.series =
            res.data.data.formattedMonthlyStudentsJoined.seriesData;

          const applicationStatusCount = res.data.data.applicationStatusCount;

          this.applicationStatusCount = applicationStatusCount;

          this.selectedStatusId = applicationStatusCount[0].status_id;
          this.selectedStatus = applicationStatusCount[0];

          this.pyramidChartData = res.data.data.pyramidChartData;

          if (applicationStatusCount.length > 1) {
            this.applicationsStatus = applicationStatusCount.map(
              (i) => i.count
            );

            this.applicationsStatusLabels = applicationStatusCount.map(
              (i) => i.status_name
            );
          }

          this.quiz_responses = {};
          if (
            this.leadType !== "direct" &&
            this.leadSource &&
            this.user_type === "counselor"
          ) {
            const res = await CounselorServices.getFranchiseQuizResponse({
              agent_user_id: this.leadSource,
            });

            if (res.data.status) {
              this.quiz_responses = res.data.data;
              // this.calculateCounts();
            }
          }
        } else {
          this.resetValues();
        }
      } catch (error) {
        console.error(`Error in getHomeData `, error);
      }
    },
    // calculateCounts() {
    //   this.quiz_responses.forEach((franchise) => {
    //     franchise.correctCount = franchise.videos.reduce((accVideo, video) => {
    //       const videoCorrect = video.questions.reduce(
    //         (accQuestion, question) => {
    //           return (
    //             accQuestion +
    //             question.answers.reduce((accAnswer, answer) => {
    //               return accAnswer + (answer.is_correct === "Y" ? 1 : 0);
    //             }, 0)
    //           );
    //         },
    //         0
    //       );

    //       video.correct = videoCorrect;

    //       return videoCorrect + accVideo;
    //     }, 0);
    //     franchise.totalCount = franchise.videos.reduce((accVideo, video) => {
    //       const videoTotal = video.questions.reduce((accQuestion, question) => {
    //         return accQuestion + question.answers.length;
    //       }, 0);

    //       video.total = videoTotal;

    //       return videoTotal + accVideo;
    //     }, 0);
    //   });
    // },
  },
  beforeMount() {
    this.getHomeData();
  },
};
</script>

<style></style>
