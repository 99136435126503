<template>
  <div class="mb-2">
    <div>
      <h4 class="dashboard__heading">Profile Status</h4>
    </div>
    <div class="profile-status__cards-grid">
      <b-card class="card-app-design profile-status__card">
        <div class="d-flex">
          <div>
            <h1 class="step-num">1</h1>
          </div>
          <div class="pl-2">
            <b-card-title class="mb-75 step-name"> Students</b-card-title>
            <b-card-text class="step-status">
              <h2>{{ data.student_count }}</h2>
            </b-card-text>
          </div>
        </div>
      </b-card>
      <b-card class="card-app-design profile-status__card">
        <div class="d-flex">
          <div>
            <h1 class="step-num">2</h1>
          </div>
          <div class="pl-2">
            <b-card-title class="mb-75 step-name">
              Converted Students Count</b-card-title
            >
            <b-card-text class="step-status">
              <h2>{{ data.converted_students_count }}</h2>
            </b-card-text>
          </div>
        </div>
      </b-card>
      <b-card class="card-app-design profile-status__card">
        <div class="d-flex">
          <div>
            <h1 class="step-num">3</h1>
          </div>
          <div class="pl-2">
            <b-card-title class="mb-75 step-name">
              Applications Count</b-card-title
            >
            <b-card-text class="step-status">
              <h2>{{ data.applications_count }}</h2>
            </b-card-text>
          </div>
        </div>
      </b-card>
    </div>
  </div>
</template>
<script>
import {
  BCard,
  BBadge,
  BCardTitle,
  BCardText,
  BAvatar,
  BButton,
  BProgress,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";

export default {
  components: {
    BCard,
    BBadge,
    BButton,
    BAvatar,
    BCardTitle,
    BCardText,
    BProgress,
  },
  directives: {
    Ripple,
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      // profile_data: this.$props.profile_data
    };
  },
};
</script>
<style>
.profile-status__progress-grid {
  display: grid;
  grid-template-columns: 80% 10%;
  grid-column-gap: 5%;
  align-items: center;
}
.profile-status__cards-grid {
  display: grid;
  /* grid-template-columns: repeat(5, 18%); */
  grid-template-columns: repeat(3, 32%);
  grid-column-gap: 2%;
}
.profile-status__card {
  background: #ffffff;
  font-weight: 600;
  padding: 10px 05px;
  /* border-radius: 12px; */
  /* padding: 1.5rem 1rem !important; */
  /* display: grid;
  grid-template-columns: 20% 80%; */
}

.profile-status__card .step-num {
  color: #8196C6;
  font-size: 45px;
  font-weight: bold;
  margin-top: -6px;
}
.profile-status__card .step-name {
  font-weight: bold;
  color: #000;
  line-height: 22px;
  font-size: 1.2rem;
  /* padding-left: 15px; */
}

.profile-status__card .step-status {
  color: #c8c8c8;
  font-weight: 600;
  margin-bottom: 0px;
  margin-top: 1rem !important;

  /* margin-left: 2rem !important; */
}
.profile-status__card.active {
  background: #fff;
  color: #fff;
}
.profile-status__card.active .step-num {
  color: rgba(247, 6, 6, 0.3);
}
.profile-status__card.active .step-name {
  color: #fff;
}
.profile-status__card.active .step-status {
  color: #EEBA8A;
}
@media (max-width: 768px) {
  .profile-status__cards-grid {
    grid-template-columns: repeat(5, 50%);
    grid-column-gap: 2%;
    overflow: auto;
  }
}
</style>