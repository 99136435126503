<!-- <template>
  <div>
    <b-modal
      id="modal-2"
      ref="response-modal-ref"
      hide-footer
      size="lg"
      title="Quiz Results"
    >
      <div>
        <div class="w-100 my-2 mb-2">
          <v-select
            class="w-100"
            v-model="currentQuizAttempt"
            :options="getAttemptOptions()"
            @input="getCurrentResponse"
            label="label"
            :clearable="false"
          >
            <template #option="data">
              <div class="d-flex justify-content-between" style="width: 700px">
                <p>Quiz Attempt {{ data.label }}</p>
                <p>
                  {{
                    moment(
                      currentVideoResponses[data.label - 1][0].created_at
                    ).format("DD MMM, YYYY")
                  }}
                </p>
              </div>
            </template>
            <template #selected-option="data">
              <div class="d-flex justify-content-between" style="width: 700px">
                <p>Quiz Attempt {{ data.label }}</p>
                <b-badge variant="warning">
                  {{
                    moment(
                      currentVideoResponses[data.label - 1][0].created_at
                    ).format("DD MMM, YYYY")
                  }}
                </b-badge>
              </div>
            </template>
          </v-select>
        </div>
        <div class="my-2">
          <h3>
            Score:
            <b-badge variant="primary"
              >{{ getCurrentResponsesScore().percentage }}%</b-badge
            >
          </h3>
          <h5>
            {{ getCurrentResponsesScore().correct_answers_count }} out of
            {{ getCurrentResponsesScore().total_count }} questions were answered
            correctly.
          </h5>
        </div>
        <div>
          <b-table hover :items="currentResponses">
            <template #cell(result)="data">
              <div style="width: max-content">
                <b-badge
                  class="rounded-pill"
                  :variant="
                    data.item.result === 'Correct'
                      ? 'light-success'
                      : 'light-danger'
                  "
                  >{{ data.item.result }}</b-badge
                >
              </div>
            </template>
          </b-table>
        </div>
        <div class="mt-5 d-flex justify-content-center">
          <button @click="closeResponseModal()" class="btn btn-success">
            Close
          </button>
        </div>
      </div>
    </b-modal>

    <b-card title="Quiz Responses">
      <app-collapse class="border rounded-lg overflow-hidden shadow-sm">
        <app-collapse-item
          v-for="(franchise, franchiseIndex) in franchiseTrainings"
          :key="franchiseIndex"
          :title="`${franchiseIndex + 1}. ${franchise.ft_name}`"
        >
          <div class="d-flex justify-content-end align-items-center mb-3">
          <span>
            Success:
            {{ calcPercent(franchise.correctCount, franchise.totalCount) }}
          </span>
        </div>

          <div
            v-for="(video, videoIndex) in franchise.videos"
            :key="videoIndex"
            class="border-bottom d-flex p-1 align-items-center"
          >
            <h5 style="font-weight: bold; min-width: 500px" class="mr-2">
              {{ `${video.ftv_name}` }}
            </h5>
            <b-badge
              class="btn btn-primary btn-sm"
              @click="showResponseModal(video.responses)"
            >
              View Results
            </b-badge>
          </div>
        </app-collapse-item>
      </app-collapse>
      <div v-if="quiz_responses && quiz_responses.length === 0">
        <p>No data available</p>
      </div>
    </b-card>
  </div>
</template> -->

<script>
import { BCollapse, BCard, BTable, BBadge } from "bootstrap-vue";
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";
import vSelect from "vue-select";
import moment from "moment";

export default {
  components: {
    BCollapse,
    BCard,
    AppCollapse,
    AppCollapseItem,
    BTable,
    vSelect,
    BBadge,
  },
  props: {
    quiz_responses: {
      type: Array,
      default: [],
    },
  },
  data() {
    return {
      allQuizAttempts: [],
      currentVideoResponses: [],
      currentResponses: [],
      franchiseTrainings: [],
      currentQuizAttempt: null,
    };
  },
  watch: {
    quiz_responses: {
      handler: "modifyQuizAttempts",
      immediate: true, // call the handler immediately on component load
    },
  },
  methods: {
    // calcPercent(partialValue, totalValue) {
    //   return `${((100 * partialValue) / totalValue).toFixed(0)}%`;
    // },
    moment,
    getAttemptOptions() {
      return (
        this.currentVideoResponses
          // .filter((response) => response[0].ftv_id === video_id)
          .map((response, index) => index + 1)
      );
    },

    showResponseModal(responses) {
      this.currentVideoResponses = this.getResponsesByAttemptId(responses);
      this.currentQuizAttempt = this.currentVideoResponses.length;
      this.getCurrentResponse();
      this.$refs["response-modal-ref"].show();
    },

    getCurrentResponsesScore() {
      let total_count = this.currentResponses.length;
      let correct_answers_count = this.currentResponses.filter(
        (response) => response.result === "Correct"
      ).length;
      let percentage = (correct_answers_count / total_count) * 100;
      percentage = percentage.toFixed(2);

      return {
        total_count,
        correct_answers_count,
        percentage,
      };
    },

    closeResponseModal() {
      this.$refs["response-modal-ref"].hide();
    },

    getCurrentResponse() {
      this.currentResponses = this.currentVideoResponses[
        this.currentQuizAttempt - 1
      ].map((response) => ({
        question: response.ftq_text,
        selected_answer: response.fta_text,
        result: response.is_correct === "Y" ? "Correct" : "Incorrect",
      }));

      this.currentResponsesWithDate = this.currentVideoResponses[
        this.currentQuizAttempt - 1
      ].map((response) => ({
        question: response.ftq_text,
        selected_answer: response.fta_text,
        result: response.is_correct === "Y" ? "Correct" : "Incorrect",
        date: response.created_at,
      }));
    },

    modifyQuizAttempts() {
      console.log({ quiz_responses: this.quiz_responses });

      for (let key in this.quiz_responses) {
        let videos = [];

        for (let videoKey in this.quiz_responses[key].videos) {
          videos.push(this.quiz_responses[key].videos[videoKey]);
        }

        this.franchiseTrainings.push({ ...this.quiz_responses[key], videos });
      }

      console.log({ franchiseTrainings: this.franchiseTrainings });
    },

    getResponsesByAttemptId(responses) {
      console.log({ allQuizResponses: responses });

      let groupedByAttemptId = [];
      let prevAttemptId = null;

      for (const obj of responses) {
        const attemptId = obj.attempt_id;
        if (!attemptId) continue;

        if (attemptId !== prevAttemptId) {
          // If attemptId is different from the previous one, start a new array
          groupedByAttemptId.push([]);
          prevAttemptId = attemptId;
        }

        // Push the object to the last array (the one just created or the last one modified)
        groupedByAttemptId[groupedByAttemptId.length - 1].push(obj);
      }

      return groupedByAttemptId;
    },
  },
  beforeMount() {
    this.modifyQuizAttempts();
  },
};
</script>

<style>
</style>