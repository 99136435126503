<template>
  <b-card v-if="data && data.length">
    <div class="w-100 d-flex justify-content-center align-items-center">
      <b-card class="pyramid">
        <div
          class="d-flex align-items-center justify-content-center flex-column h-100"
          style="width: 800px"
        >
          <div
            v-for="(application, index) in getComputedApplicationData(data)"
            class="w-100 d-flex align-items-center justify-content-center"
            :key="'application_' + index"
          >
            <div class="left-cover" :style="application.coverStyle"></div>
            <div class="section" :style="application.styles">
              <h4 class="p-0 m-0 text-primary">{{ application.count }}</h4>
              <span class="text-center">{{ application.title }}</span>
            </div>
            <div class="right-cover" :style="application.coverStyle"></div>
          </div>
        </div>
      </b-card>
    </div>
  </b-card>
</template>

<script>
import { BRow, BCol, BCard, componentsPlugin } from "bootstrap-vue";

export default {
  components: {
    BCard,
  },
  data() {
    return {
      colorsArray: ["#D5EDCD", "#F6DC80", "#DDD1FC", '#EEBA8A'],
    };
  },
  props: {
    data: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    getComputedApplicationData(applicationData) {
      let finalApplicationData = [];

      // sort by count
      // for (let i = 0; i < applicationData.length - 1; i++) {
      //   let currentLargestIndex = i;
      //   for (let j = i + 1; j <= applicationData.length - 1; j++) {
      //     if (
      //       applicationData[currentLargestIndex].count <
      //       applicationData[j].count
      //     ) {
      //       currentLargestIndex = j;
      //     }
      //   }
      //   if (currentLargestIndex != i) {
      //     let temp = applicationData[i];
      //     applicationData[i] = applicationData[currentLargestIndex];
      //     applicationData[currentLargestIndex] = temp;
      //   }
      // }

      let width = 400;
      let height = "80px";
      let colorIndex = 0;

      for (let application of applicationData) {
        finalApplicationData.push({
          ...application,
          styles: {
            height,
            width: width.toString() + "px",
            backgroundColor: this.colorsArray[colorIndex],
          },
          coverStyle: {
            borderTop: `80px solid ${this.colorsArray[colorIndex]}`,
          },
        });
        width = width - 100;
        if (colorIndex == this.colorsArray.length - 1) colorIndex = 0;
        else colorIndex++;
      }

      let lastBlockColorIndex =
        colorIndex == 0 ? this.colorsArray.length - 1 : colorIndex - 1;
      finalApplicationData.push({
        id: null,
        title: "",
        count: null,
        styles: {
          height: 0,
          width: 0,
          backgroundColor: this.colorsArray[lastBlockColorIndex],
        },
        coverStyle: {
          borderTop: `80px solid ${this.colorsArray[lastBlockColorIndex]}`,
        },
      });

      return finalApplicationData;
    },
  },
};
</script>

<style>
.triangle {
}
.pyramid {
  height: 440px;
  width: 900px;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.left-cover {
  width: 0;
  height: 0;
  border-left: 50px solid transparent; /* Adjust the size as needed */
}
.right-cover {
  width: 0;
  height: 0;
  border-right: 50px solid transparent; /* Adjust the size as needed */
}
</style>
